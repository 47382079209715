import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { RIDEROPERATORREFERRAL } = actions;

export const fetchSubOpsReferredRider = (payload) => async (dispatch, getState) => {
    dispatch({ type: RIDEROPERATORREFERRAL.FETCH.REQUEST });

    console.log("payload>>><><><:", payload.role)

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    let { id } = userInfo;
  
    try {
      const { page, limit, searchText, role } = payload;
      let url = `/api/sub-ops-referral/subOps-referral/${id}/${role}`;
      console.log("ROLES>>>>:", payload)
      if (payload !== undefined) {
        
        url = `/api/sub-ops-referral/subOps-referral/${id}/${role || payload}?page=${page}&limit=${limit}`;
        if (page && limit && searchText) {
          url = `/api/sub-ops-referral/subOps-referral/${id}/${role || payload}?page=${page}&limit=${limit}&searchText=${searchText}`;
        }
      }
  
      const response = await axiosInstance(token).get(url);
  
      dispatch({
        type: RIDEROPERATORREFERRAL.FETCH.SUCCESS,
        payload: response,
      });
  
      return response;
    } catch (error) {
      dispatch({
        type: RIDEROPERATORREFERRAL.FETCH.FAILED,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };
import actions from "../actionTypes";
const { RIDER_PACKAGES } = actions;

const riderPackagesInitialState = {
    modal: false,
    modalView: false,
    formLoading: false,
    data: [],
    form: {},
    loading: false,
    error: null,
    totalRecord: 0,
    nextPage: undefined,
    previousPage: undefined,
    currentPage: undefined,
    packageStatus: "",
    message: "",      
  };
  
  export const riderPackagesReducers = (state = riderPackagesInitialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case RIDER_PACKAGES.FETCH.REQUEST:
        return { ...state, loading: true };
      case RIDER_PACKAGES.FETCH.SUCCESS:
        return {
          ...state,
          data: payload.data,
          totalRecord: payload.totalRecord,
          nextPage: payload?.nextPage,
          previousPage: payload?.previousPage,
          currentPage: payload?.currentPage,
          loading: false,
        };
      case RIDER_PACKAGES.FETCH.FAILURE:
        return { ...state, error: payload, loading: false };
  
      case RIDER_PACKAGES.SENDPACKAGE.REQUEST:
        return { ...state, formLoading: true };
      case RIDER_PACKAGES.SENDPACKAGE.SUCCESS:
        return { 
          ...state, 
          packageStatus: "Package sent", 
          message: payload.message,   
          formLoading: false 
        };
      case RIDER_PACKAGES.SENDPACKAGE.FAILURE:
        return { ...state, error: payload, formLoading: false };
  
      default:
        return state;
    }
  };
import axiosInstance from "../../helpers/axios.js";

import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
} from "../actionTypes.js";

export const login =
  ({ username, password }) =>
  async (dispatch) => {
    try {
      dispatch({ type: USER_LOGIN_REQUEST });

      const { data } = await axiosInstance().post(
        "/api/operator-admin-account/login",
        {
          username,
          password,
        }
      );

      console.log("data>>>:::", data);

      if (data.role === "operator_admin" || data.role === "sub_operator") {
        dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
      } else {
        dispatch({
          type: USER_LOGIN_FAIL,
          payload: "Account is not an operator admin",
        });
      }

      try {
        localStorage.setItem("userInfo", JSON.stringify(data));
      } catch (e) {
        console.log(">>> error saving values from userInfo key");
      }
      return data;
    } catch (error) {
      let message = "";
      if (error.response?.status === 401) {
        message = "Invalid Username or Password";
      } else {
        console.log("An error occurred:", error);
        message = "Something Went Wrong";
      }

      dispatch({
        type: USER_LOGIN_FAIL,
        payload: message,
      });
    }
  };

export const logout = () => async (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: USER_LOGOUT });
};

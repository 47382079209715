import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  Layout,
  Form,
  Input,
  Badge,
  Tooltip,
  Button,
  Popconfirm,
  DatePicker,
  Card,
  Modal,
  Skeleton,
} from "antd";
import { CheckSquareOutlined, ArrowDownOutlined } from "@ant-design/icons";
import moment from "moment/moment";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";

// helpers
import formatCurrency from "../helpers/formatCurrency";

// Actions
import {
  fetchTotalBalance,
  fetchPartnerTransactions,
  fetchAllCommission,
} from "../redux/actions/partnerFinance";

const { Search } = Input;

const PartnerFinance = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { RangePicker } = DatePicker;

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);
  const [toSelectedDate, setToSelectedDate] = useState(null);
  const [toformattedDate, setToFormattedDate] = useState(null);

  const [modal, setModal] = useState(false);
  const [partnerCommision, setPartnerCommission] = useState(0);
  const [riderReferrals, setRiderReferrals] = useState(0);
  const [merchantCommission, setMerchantCommission] = useState(0);
  const [markUpCommission, setMarkUpCommission] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [filteredTotalBalance, setFilteredTotalBalance] = useState(0);
  const [
    filteredRiderReferralsCommission,
    setFilteredRiderReferralsCommission,
  ] = useState(0);
  const [filteredPartnerCommission, setFilteredPartner_commission] =
    useState(0);
  const [filteredMerchantCommissions, setFilteredMerchantCommissions] =
    useState(0);

  const [filteredMarkUpCommissions, setFilteredMarkUpCommissions] = useState(0);

  const {
    loading,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
    totalBalance,
    data,
    rider_referrals,
    merchant_commissions,
    parner_commission,
    totalBalanceLoading,
    loadingAllCommission,
    markup_commissions,
    dataFiltered,
  } = useSelector(
    ({ partnerFinanceReducer }) => ({
      data: partnerFinanceReducer.data,
      totalRecord: partnerFinanceReducer.totalRecord,
      nextPage: partnerFinanceReducer.nextPage,
      previousPage: partnerFinanceReducer.previousPage,
      currentPage: partnerFinanceReducer.currentPage,
      loading: partnerFinanceReducer.loading,
      totalBalance: partnerFinanceReducer.totalBalance,
      parner_commission: partnerFinanceReducer.parner_commission,
      rider_referrals: partnerFinanceReducer.rider_referrals,
      merchant_commissions: partnerFinanceReducer.merchant_commissions,
      markup_commissions: partnerFinanceReducer.markup_commissions,
      totalBalanceLoading: partnerFinanceReducer.loadingTotalBalance,
      loadingAllCommission: partnerFinanceReducer.loadingAllCommission,
      dataFiltered: partnerFinanceReducer.dataFiltered,
    }),
    isEqual
  );
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  console.log(">>> totalBalance:", totalBalance);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const formatDate = (date) => {
      return date ? dayjs(date).format("YYYY-MM-DD") : undefined;
    };
    const i = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
    };
    const payload = {
      searchText: delaySearchText,
      page: 1,
      limit: 15,
      fromdateFilter:
        formatDate(selectedDate) === "Invalid Date"
          ? undefined
          : formatDate(selectedDate) + "T" + "00:00:00",
      todateFilter:
        formatDate(toSelectedDate) === "Invalid Date"
          ? undefined
          : formatDate(toSelectedDate) + "T" + "23:59:59",
    };

    if (formatDate(selectedDate) !== undefined) {
      if (payload.searchText !== "") {
        dispatch(fetchPartnerTransactions(i));
      } else {
        dispatch(fetchPartnerTransactions(payload));
      }
    } else {
      dispatch(fetchPartnerTransactions(i));
    }
  }, [delaySearchText, toSelectedDate, selectedDate, dispatch]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };
    dispatch(fetchPartnerTransactions(payload));
    if (payload.searchText === "") {
      dispatch(fetchPartnerTransactions(payload));
    }
  };

  // useEffect(() => {
  //   const payload = {
  //     page: 1,
  //     limit: 15,
  //     searchText,
  //   };
  //   if (payload.searchText === "") {
  //     dispatch(fetchPartnerTransactions(payload));
  //   }
  // }, [dispatch]);

  useEffect(() => {
    const i =
      (parseFloat(parner_commission) / parseFloat(totalBalance)) * 100 || 0;
    const b =
      (parseFloat(rider_referrals) / parseFloat(totalBalance)) * 100 || 0;
    const j =
      (parseFloat(merchant_commissions) / parseFloat(totalBalance)) * 100 || 0;

    const v =
      (parseFloat(markup_commissions) / parseFloat(totalBalance)) * 100 || 0;

    const a =
      parseFloat(parner_commission) +
      parseFloat(rider_referrals) +
      parseFloat(merchant_commissions) +
      parseFloat(markup_commissions);
    setTotalEarnings(a);
    setPartnerCommission(i.toFixed(2));
    setRiderReferrals(b.toFixed(2));
    setMerchantCommission(j.toFixed(2));
    setMarkUpCommission(v.toFixed(2));
  }, [
    totalBalance,
    merchant_commissions,
    rider_referrals,
    parner_commission,
    selectedDate,
  ]);

  useEffect(() => {
    let filterBalance = [];
    let filteredDfCommission = [];
    let filteredRrCommission = [];
    let filteredMCommission = [];
    let filteredMarpUpCommission = [];
    if (dataFiltered.length !== 0) {
      dataFiltered?.rows
        .filter((dd) => dd.entry_type === "debit")
        .map((d) => filterBalance.push(d.amount));

      const totalFilterBalance = filterBalance.reduce(
        (sum, amount) => sum + parseFloat(amount),
        0
      );
      setFilteredTotalBalance(totalFilterBalance.toFixed(2));
      // Df commission
      dataFiltered?.rows
        .filter((d) => d.transaction_type_id === 7)
        .map((dd) => filteredDfCommission.push(dd.amount));
      const totalFilteredDfCommission = filteredDfCommission.reduce(
        (sum, amount) => sum + parseFloat(amount),
        0
      );
      const i =
        (parseFloat(totalFilteredDfCommission) /
          parseFloat(totalFilterBalance)) *
          100 || 0;
      setFilteredPartner_commission(totalFilteredDfCommission.toFixed(2));
      setPartnerCommission(i.toFixed(2));

      // Rrc commission
      dataFiltered?.rows
        .filter((d) => d.transaction_type_id === 15)
        .map((dd) => filteredRrCommission.push(dd.amount));
      const totalFilteredRrCommission = filteredRrCommission.reduce(
        (sum, amount) => sum + parseFloat(amount),
        0
      );
      const b =
        (parseFloat(totalFilteredRrCommission) /
          parseFloat(totalFilterBalance)) *
          100 || 0;
      setFilteredRiderReferralsCommission(totalFilteredRrCommission.toFixed(2));
      setRiderReferrals(b.toFixed(2));

      // M commission
      dataFiltered?.rows
        .filter((d) => d.transaction_type_id === 21)
        .map((dd) => filteredMCommission.push(dd.amount));
      const totalFilteredMCommission = filteredMCommission.reduce(
        (sum, amount) => sum + parseFloat(amount),
        0
      );
      const j =
        (parseFloat(totalFilteredMCommission) /
          parseFloat(totalFilterBalance)) *
          100 || 0;
      setMerchantCommission(j.toFixed(2));
      setFilteredMerchantCommissions(totalFilteredMCommission.toFixed(2));

      // Mark up Commissions
      dataFiltered?.rows
        .filter((d) => d.transaction_type_id === 25)
        .map((dd) => filteredMarpUpCommission.push(dd.amount));
      const totalFilteredMarkupCommission = filteredMarpUpCommission.reduce(
        (sum, amount) => sum + parseFloat(amount),
        0
      );
      const markUp =
        (parseFloat(totalFilteredMarkupCommission) /
          parseFloat(totalFilterBalance)) *
          100 || 0;
      setMarkUpCommission(markUp.toFixed(2));
      setFilteredMarkUpCommissions(totalFilteredMarkupCommission.toFixed(2));
    } else {
      setFilteredTotalBalance(0);
      setFilteredPartner_commission(0);
      setFilteredRiderReferralsCommission(0);
      setFilteredMerchantCommissions(0);
    }
  }, [dataFiltered]);

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1 style={{ marginTop: -30, fontSize: 30, marginBottom: -5 }}>
          All transactions
        </h1>

        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    const formatDate = (date) => {
      return date ? dayjs(date).format("YYYY-MM-DD") : undefined;
    };
    if (formatDate(selectedDate) !== undefined) {
      dispatch(
        fetchPartnerTransactions({
          ...nextPage,
          fromdateFilter:
            formatDate(selectedDate) === "Invalid Date"
              ? undefined
              : formatDate(selectedDate) + "T" + "00:00:00",
          todateFilter:
            formatDate(toSelectedDate) === "Invalid Date"
              ? undefined
              : formatDate(toSelectedDate) + "T" + "23:59:59",
        })
      );
    } else {
      dispatch(
        fetchPartnerTransactions({
          ...nextPage,
        })
      );
    }
  };

  const onClickPreviousPage = () => {
    const formatDate = (date) => {
      return date ? dayjs(date).format("YYYY-MM-DD") : undefined;
    };
    if (formatDate(selectedDate) !== undefined) {
      dispatch(
        fetchPartnerTransactions({
          ...previousPage,
          fromdateFilter:
            formatDate(selectedDate) === "Invalid Date"
              ? undefined
              : formatDate(selectedDate) + "T" + "00:00:00",
          todateFilter:
            formatDate(toSelectedDate) === "Invalid Date"
              ? undefined
              : formatDate(toSelectedDate) + "T" + "23:59:59",
        })
      );
    } else {
      dispatch(
        fetchPartnerTransactions({
          ...previousPage,
        })
      );
    }
  };

  const onClickFirstPage = () => {
    const formatDate = (date) => {
      return date ? dayjs(date).format("YYYY-MM-DD") : undefined;
    };
    if (formatDate(selectedDate) !== undefined) {
      dispatch(
        fetchPartnerTransactions({
          ...defaultPage,
          fromdateFilter:
            formatDate(selectedDate) === "Invalid Date"
              ? undefined
              : formatDate(selectedDate) + "T" + "00:00:00",
          todateFilter:
            formatDate(toSelectedDate) === "Invalid Date"
              ? undefined
              : formatDate(toSelectedDate) + "T" + "23:59:59",
        })
      );
    } else {
      dispatch(
        fetchPartnerTransactions({
          ...defaultPage,
        })
      );
    }
  };

  const onClickLastPage = () => {
    const formatDate = (date) => {
      return date ? dayjs(date).format("YYYY-MM-DD") : undefined;
    };
    if (formatDate(selectedDate) !== undefined) {
      dispatch(
        fetchPartnerTransactions({
          page: Math.ceil(totalRecord / 15),
          limit: 15,
          fromdateFilter:
            formatDate(selectedDate) === "Invalid Date"
              ? undefined
              : formatDate(selectedDate) + "T" + "00:00:00",
          todateFilter:
            formatDate(toSelectedDate) === "Invalid Date"
              ? undefined
              : formatDate(toSelectedDate) + "T" + "23:59:59",
        })
      );
    } else {
      dispatch(
        fetchPartnerTransactions({
          page: Math.ceil(totalRecord / 15),
          limit: 15,
        })
      );
    }
  };
  //   const onCheck = async (payload) => {
  //     dispatch(updatePayablesStatus(payload));
  //   };

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      setSelectedDate(dates[0]);

      setToSelectedDate(dates[1]);
    } else {
      setSelectedDate(null);
      setToSelectedDate(null);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Ammount",
        accessor: (row) => {
          if (row.entry_type === "debit") {
            return <div style={{ color: "green" }}> + {row.amount}</div>;
          }
          if (row.entry_type === "credit") {
            return <div style={{ color: "tomato" }}> - {row.amount}</div>;
          }
        },
      },

      {
        Header: "Reference Number",
        accessor: "reference_number",
      },
      {
        Header: "Date",
        accessor: (row) => {
          const utcTime = moment.utc(row.created_at);
          return utcTime.format("MMM D, YYYY hh:mm A"); // Format the time as needed
        },
      },
      {
        Header: "Transaction type",
        accessor: (row) => row["transaction_type.description"],
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchPartnerTransactions(defaultPage));
  }, [dispatch, defaultPage]);
  useEffect(() => {
    dispatch(fetchTotalBalance());
    dispatch(fetchAllCommission());
  }, [dispatch]);
  const renderContent = () => {
    return (
      <div style={{ overflowX: "auto" }}>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            gap: 20,
          }}
        >
          <h1 style={{ fontSize: 30, marginLeft: 45 }}>E-wallet</h1>
          <div style={{ marginBottom: 10 }}>
            <RangePicker
              style={{ width: "20vw" }}
              value={[selectedDate, toSelectedDate]}
              onChange={handleDateChange}
              placeholder={["Start Date", "End Date"]}
              format="YYYY-MM-DD"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 5,
            alignItems: "flex-start",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Card
            style={{
              boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
              overflowX: "auto",
              width: screenWidth < 1920 && "100%",
            }}
          >
            <div style={{ width: screenWidth < 1920 ? "100%" : "50%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 9,
                }}
              >
                {totalBalanceLoading ? (
                  <Skeleton.Button
                    active
                    style={{
                      width: 640,
                      marginLeft: 25,
                      marginBottom: 20,
                      height: 130,
                    }}
                    loading={totalBalanceLoading}
                  />
                ) : (
                  <Card
                    style={{
                      width: 640,
                      boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
                      marginLeft: 25,
                      marginBottom: 20,
                      height: 130,
                    }}
                  >
                    <div
                      style={{
                        marginTop: -20,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <h1
                          style={{ fontSize: 20, marginTop: 17, color: "gray" }}
                        >
                          Available Balance
                        </h1>
                        <h2 style={{ fontSize: 30, marginTop: -10 }}>
                          {/* {formatCurrency(totalBalance || 0)} */}
                          ----
                        </h2>
                      </div>

                      {/* <Button
                        onClick={() => setModal(true)}
                        type="primary"
                        htmlType="submit"
                        style={{
                          width: 145,
                          height: 65,
                          fontSize: 20,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: 5,
                        }}
                      >
                        <ArrowDownOutlined />
                        <div>Withdraw</div>
                      </Button> */}

                      <Button
                        style={{
                          width: 145,
                          height: 65,
                          fontSize: 20,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: 5,
                          // backgroundColor: "#1677ff",
                          backgroundColor: "gray",
                          color: "#fff",
                          borderRadius: 12,
                        }}
                        onClick={() => {
                          history.push({
                            pathname: "/fund-transfer",
                            state: { availableBalance: totalBalance },
                          });
                        }}
                        // disabled={totalBalance == 0}
                        disabled={true}
                        // to={"/fund-transfer"}
                        // state={{ availableBalance: totalBalance }}
                      >
                        Withdraw
                      </Button>
                    </div>
                  </Card>
                )}

                {loadingAllCommission ? (
                  <Skeleton.Button
                    active
                    style={{
                      marginLeft: 25,
                      marginBottom: 15,
                      width: 640,
                      height: 130,
                    }}
                    loading={loadingAllCommission}
                  />
                ) : (
                  <Card
                    style={{
                      marginLeft: 25,
                      marginBottom: 15,
                      width: 640,
                      height: 130,
                      boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <div style={{ marginTop: -20 }}>
                      <h1
                        style={{ fontSize: 20, marginTop: 17, color: "gray" }}
                      >
                        Total Earnings
                      </h1>

                      <h2 style={{ fontSize: 30, marginTop: -10 }}>
                        {filteredTotalBalance !== 0
                          ? formatCurrency(filteredTotalBalance)
                          : formatCurrency(totalEarnings || 0)}
                      </h2>
                    </div>
                  </Card>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 10,
                  width: 700,
                  flexWrap: "wrap",
                }}
              >
                {loadingAllCommission ? (
                  <Skeleton.Button
                    active
                    style={{
                      marginLeft: 25,
                      marginBottom: 15,
                      width: 302,
                      height: 110,
                    }}
                    loading={loadingAllCommission}
                  />
                ) : (
                  <Card
                    title={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Area Commission {"(10%)"}</div>
                      </div>
                    }
                    style={{
                      marginLeft: 25,
                      marginBottom: 15,
                      width: 302,
                      height: 110,
                      boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <h2 style={{ fontSize: 20, marginTop: -10 }}>
                        {filteredPartnerCommission !== 0
                          ? formatCurrency(filteredPartnerCommission)
                          : formatCurrency(parner_commission)}
                      </h2>
                      <div style={{ color: "green", marginTop: -10 }}>
                        {partnerCommision || 0} %
                      </div>
                    </div>
                  </Card>
                )}

                {loadingAllCommission ? (
                  <Skeleton.Button
                    active
                    style={{
                      marginLeft: 25,
                      marginBottom: 15,
                      width: 302,
                      height: 110,
                    }}
                    loading={loadingAllCommission}
                  />
                ) : (
                  <Card
                    title={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Rider Referral Commission {"(5%)"}</div>
                      </div>
                    }
                    style={{
                      marginLeft: 25,
                      marginBottom: 15,
                      width: 302,
                      height: 110,
                      boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <h2 style={{ fontSize: 20, marginTop: -10 }}>
                        {filteredRiderReferralsCommission !== 0
                          ? formatCurrency(filteredRiderReferralsCommission)
                          : formatCurrency(rider_referrals)}
                      </h2>
                      <div style={{ color: "green", marginTop: -10 }}>
                        {riderReferrals || 0}%
                      </div>
                    </div>
                  </Card>
                )}
                {loadingAllCommission ? (
                  <Skeleton.Button
                    active
                    style={{
                      marginLeft: 25,
                      marginBottom: 15,
                      width: 302,
                      height: 110,
                    }}
                    loading={loadingAllCommission}
                  />
                ) : (
                  <Card
                    title={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Markup Commission</div>
                      </div>
                    }
                    style={{
                      marginLeft: 25,
                      marginBottom: 15,
                      width: 302,
                      height: 110,
                      boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <h2 style={{ fontSize: 20, marginTop: -10 }}>
                        {filteredMarkUpCommissions !== 0
                          ? formatCurrency(filteredMarkUpCommissions)
                          : formatCurrency(markup_commissions)}
                      </h2>
                      <div style={{ color: "green", marginTop: -10 }}>
                        {markUpCommission || 0}%
                      </div>
                    </div>
                  </Card>
                )}

                {loadingAllCommission ? (
                  <Skeleton.Button
                    active
                    style={{
                      marginLeft: 25,
                      marginBottom: 15,
                      width: 302,
                      height: 110,
                    }}
                    loading={loadingAllCommission}
                  />
                ) : (
                  <Card
                    title={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Subscription Commission {"(80%)"}</div>
                      </div>
                    }
                    style={{
                      marginLeft: 25,
                      marginBottom: 15,
                      width: 302,
                      height: 110,
                      boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <h2 style={{ fontSize: 20, marginTop: -10 }}>
                        {filteredMerchantCommissions !== 0
                          ? formatCurrency(filteredMerchantCommissions)
                          : formatCurrency(merchant_commissions)}
                      </h2>
                      <div style={{ color: "green", marginTop: -10 }}>
                        {merchantCommission || 0}%
                      </div>
                    </div>
                  </Card>
                )}
              </div>
            </div>
          </Card>
          <div style={{ width: screenWidth < 1920 ? "100%" : "50%" }}>
            <Card style={{ boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.1)" }}>
              <ServerSideTable
                columns={columns}
                data={data}
                loading={loading}
                serverSidePagination={{
                  totalRecord,
                  onClickNextPage,
                  onClickPreviousPage,
                  currentPage,
                  nextPage,
                  previousPage,
                  onClickFirstPage,
                  onClickLastPage,
                }}
                customFilters={customFilters}
                dateFilters={{ selectedDate }}
              />
            </Card>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            <Modal
              open={modal}
              onOk={() => {
                setModal(false);
              }}
              onCancel={() => {
                setModal(false);
              }}
              width={700}
            >
              <div>
                <div style={{ fontSize: 30 }}>
                  Withdraw Feature will be available soon
                </div>
              </div>
            </Modal>
          </>
        }
      />
    </>
  );
};

export default PartnerFinance;

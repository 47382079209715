import actions from "../actionTypes";
const { PARTNERFINANCE } = actions;

const userInitialState = {
  data: [],
  loading: false,
  loadingAllCommission: false,
  loadingTotalBalance: false,
  error: null,
  totalRecord: 0,
  nextPage: undefined,
  previousPage: undefined,
  currentPage: undefined,
  totalBalance: 0,
  dataFiltered: [],
};

export const partnerFinanceReducer = (state = userInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    // Total balance
    case PARTNERFINANCE.FETCHTOTALBALANCE.REQUEST:
      return { ...state, loadingTotalBalance: true };
    case PARTNERFINANCE.FETCHTOTALBALANCE.SUCCESS:
      return {
        ...state,
        totalBalance: payload.data.result.balance,
        loadingTotalBalance: false,
      };
    case PARTNERFINANCE.FETCHTOTALBALANCE.FAILURE:
      return { ...state, error: payload, loadingTotalBalance: false };

    // All commissions
    case PARTNERFINANCE.FETCHALLCOMMISSION.REQUEST:
      return { ...state, loadingAllCommission: true };
    case PARTNERFINANCE.FETCHALLCOMMISSION.SUCCESS:
      return {
        ...state,
        parner_commission: payload.data.partner_commission,
        rider_referrals: payload.data.rider_referrals,
        merchant_commissions: payload.data.merchant_commissions,
        markup_commissions: payload.data.markup_commissions,
        loadingAllCommission: false,
      };
    case PARTNERFINANCE.FETCHALLCOMMISSION.FAILURE:
      return { ...state, error: payload, loadingAllCommission: false };

    // All transaction
    case PARTNERFINANCE.FETCH.REQUEST:
      return { ...state, loading: true };
    case PARTNERFINANCE.FETCH.SUCCESS:
      return {
        ...state,
        dataFiltered: payload.data.dataFiltered,
        data: payload.data.result,
        totalRecord: payload.data.count,
        nextPage: payload.data.next,
        previousPage: payload.data.previous,
        currentPage: payload.data.currentPage,
        loading: false,
      };
    case PARTNERFINANCE.FETCH.FAILURE:
      return { ...state, error: payload, loading: false };

    default:
      return state;
  }
};

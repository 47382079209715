import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//
import { isEqual } from "lodash";
import { Layout, Form, Input, Badge, Checkbox, notification, Tag } from "antd";

// Components
import formatCurrency from "../../helpers/formatCurrency";
import ServerSideTable from "../table/serverSideTable";

import { fetch, updateMenuStatus } from "../../redux/actions/merchantMenu";
import axiosInstance from "../../helpers/axios";
const { Content: LayoutContent } = Layout;
const { Search } = Input;
const AllMenuItemsTableComponent = ({
  merchantId,
  setSelectedMenus,
  Viewtype,
}) => {
  const dispatch = useDispatch();
  // const { merchantId, merchantName, merchantTypeId } = useParams();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [checkedMenu, setCheckedMenu] = useState([]);
  const [filterdData, setFilterdData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const {
    loading,
    formLoading,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
    data,
    form,
    modalDuplicate,
    userInfo,
  } = useSelector(
    ({ MerchantMenuReducer, userLoginReducer }) => ({
      data: MerchantMenuReducer.data,
      loading: MerchantMenuReducer.loading,
      formLoading: MerchantMenuReducer.formLoading,
      totalRecord: MerchantMenuReducer.totalRecord,
      nextPage: MerchantMenuReducer.nextPage,
      previousPage: MerchantMenuReducer.previousPage,
      currentPage: MerchantMenuReducer.currentPage,
      form: MerchantMenuReducer.form,
      modalDuplicate: MerchantMenuReducer.modalDuplicate,
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );

  useEffect(() => {
    if (Viewtype === "View") {
      let markedUpData = [];
      setTableLoading(true);
      data
        .filter((d) => d.mark_up_percentage !== "0.00")
        .map((dd) => markedUpData.push(dd));

      setFilterdData(markedUpData);
      setTableLoading(false);
    }
  }, [Viewtype, data]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
      userId: merchantId,
    };

    dispatch(fetch(payload));
  };

  const checkBox = (item) => {
    setCheckedMenu((prevCheckedMenu) => {
      const isItemChecked = prevCheckedMenu.some(
        (checkedItem) => checkedItem === item
      );
      if (!isItemChecked) {
        return [...prevCheckedMenu, item];
      } else {
        return prevCheckedMenu.filter((checkedItem) => checkedItem !== item);
      }
    });
  };

  // useEffect(() => {
  //   setSelectedMenus(checkedMenu);
  // }, [checkedMenu]);

  const customFilters = () => {
    return (
      <div className="mb-2">
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
      userId: merchantId,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetch({
        ...nextPage,
        userId: merchantId,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetch({
        ...previousPage,
        userId: merchantId,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetch({
        ...defaultPage,
        userId: merchantId,
      })
    );
  };

  useEffect(() => {
    dispatch(fetch(defaultPage));
  }, [dispatch, defaultPage, modalDuplicate]);

  const onClickLastPage = () => {
    dispatch(
      fetch({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
        userId: merchantId,
      })
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
            <Checkbox
              checked={
                checkedMenu.some((checkedItem) => checkedItem === original.id)
                  ? true
                  : false
              }
              onChange={() => checkBox(original.id)}
            ></Checkbox>
          </div>
        ),
        width: 100,
      },
      {
        Header: "Status",
        accessor: "isActive",
        Cell: ({ value }) => {
          return value === 1 ? (
            <Badge status="success" text="Active" />
          ) : (
            <Badge status="error" text="Deactivated" />
          );
        },
      },
      {
        Header: "Price",
        Cell: ({ row: { original } }) => {
          let data = [];
          if (original.Size !== null && original.Size !== "") {
            data = JSON.parse(JSON.parse(JSON.stringify(original.Size)));
          }
          return (
            <>
              {data.length ? (
                data.map((d) => (
                  <Tag>{`${d["variant"]}: ${formatCurrency(d["price"])}`}</Tag>
                ))
              ) : (
                <Tag>{formatCurrency(original.price)}</Tag>
              )}
            </>
          );
        },
      },
      {
        Header: "Mark up percentage",
        accessor: "mark_up_percentage",
        Cell: ({ value }) => {
          return (
            <p style={{ color: "black" }}>
              {parseFloat(value) % 1 === 0
                ? Math.floor(value)
                : parseFloat(value).toFixed(2)}
              %
            </p>
          );
        },
      },

      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Category",
        accessor: "category_title",
      },
    ],
    [formLoading, checkedMenu]
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      userId: merchantId,
      searchText: delaySearchText,
    };
    dispatch(fetch(payload));
  }, [delaySearchText, dispatch, merchantId]);
  console.log(data);
  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={Viewtype !== "View" ? data : filterdData}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
          extra={
            [
              // <Button key="1" onClick={onDuplicate} className="mr-2">
              //   <CopyOutlined /> Duplicate menu
              // </Button>,
            ]
          }
        />
      </LayoutContent>
    );
  };
  return <>{renderContent()}</>;
};

export default AllMenuItemsTableComponent;

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { Layout, Form, Input, Tooltip, Button, Modal, DatePicker } from "antd";
import { CheckSquareOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";

// Redux
import { fetchAllRidersPackages, sendRidersPackage } from "../redux/actions/riderPackageMonitoring";

const { Content: LayoutContent } = Layout;
const { Search } = Input;
const { RangePicker } = DatePicker;

const validationSchema = Yup.object().shape({
  trackingNumber: Yup.string()
    .required("Tracking number is required")
    .matches(/^\d{12}$/, "Tracking number must be exactly 12 digits"),
});

const RiderPackageMonitoring = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [rowData, setRowData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [toSelectedDate, setToSelectedDate] = useState(null);
  const [sentPackages, setSentPackages] = useState({});

  const { loading, riderPackageData, totalRecord, nextPage, previousPage, currentPage } = useSelector(
    ({ riderPackagesReducers }) => ({
      riderPackageData: riderPackagesReducers.data,
      totalRecord: riderPackagesReducers.totalRecord,
      nextPage: riderPackagesReducers.nextPage,
      previousPage: riderPackagesReducers.previousPage,
      currentPage: riderPackagesReducers.currentPage,
      loading: riderPackagesReducers.loading,
    }),
    isEqual
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [searchText]);

  const formatDate = (date) => (date ? dayjs(date).format("YYYY-MM-DD") : undefined);

  useEffect(() => {
    const i = { page: 1, limit: 15 };
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
      dateFilter:
        formatDate(selectedDate) === "Invalid Date"
          ? undefined
          : formatDate(selectedDate) + " " + "00:00:00",
      todateFilter:
        formatDate(toSelectedDate) === "Invalid Date"
          ? undefined
          : formatDate(toSelectedDate) + " " + "23:59:59",
    };

    if (formatDate(selectedDate) !== undefined || delaySearchText) {
      dispatch(fetchAllRidersPackages(payload));
    } else {
      dispatch(fetchAllRidersPackages(i));
    }
  }, [delaySearchText, selectedDate, dispatch, toSelectedDate]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };
    dispatch(fetchAllRidersPackages(payload));
  };

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      setSelectedDate(dates[0]);
      setToSelectedDate(dates[1]);
    } else {
      setSelectedDate(null);
      setToSelectedDate(null);
    }
  };

  const customFilters = () => (
    <div className="mb-2">
      <h1>Rider Package Monitoring</h1>
      <div className="p-2 flex justify-between" style={{ backgroundColor: "#fafafa" }}>
        <Form layout="inline">
          <Form.Item label="Search">
            <Search
              placeholder="input search text"
              allowClear
              style={{ width: "15vw" }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onSearch={onSearch}
            />
          </Form.Item>
          <RangePicker
            style={{ width: "30vw" }}
            value={[selectedDate, toSelectedDate]}
            onChange={handleDateChange}
            placeholder={["Start Date", "End Date"]}
            format="YYYY-MM-DD"
          />
        </Form>
      </div>
    </div>
  );

  const defaultPage = useMemo(() => ({ page: 1, limit: 15 }), []);
  const onClickNextPage = () => {
    dispatch(fetchAllRidersPackages({ ...nextPage }));
  };
  const onClickPreviousPage = () => {
    dispatch(fetchAllRidersPackages({ ...previousPage }));
  };
  const onClickFirstPage = () => {
    dispatch(fetchAllRidersPackages({ ...defaultPage }));
  };
  const onClickLastPage = () => {
    dispatch(fetchAllRidersPackages({ page: Math.ceil(totalRecord / 15), limit: 15 }));
  };

  const handleSendPackage = async (values) => {
    setSentPackages((prev) => ({
      ...prev,
      [rowData.rider_id]: true,
    }));

    setConfirmLoading(true);

    try {
      await dispatch(sendRidersPackage({ ...rowData, trackingNumber: values.trackingNumber }));

      setOpenModal(false);
    } catch (error) {
      console.error("Error sending package:", error);

      setSentPackages((prev) => {
        const updated = { ...prev };
        delete updated[rowData.rider_id];
        return updated;
      });
    }

    setConfirmLoading(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => {
          const isSent = sentPackages[original.id] || original.is_sent;

          return (
            <Button
              onClick={() => {
                setRowData({
                  rider_id: original.id,
                  fullName: original.fullName,
                  email_address: original.email_address,
                  contact_number: original.contact_number,
                  address: original.address,
                  item_purchase: original.item_purchase,
                  amount: original.amount,
                  size: original.size,
                });
                setOpenModal(true);
              }}
              size="small"
              type="primary"
              disabled={isSent}
            >
              <Tooltip title={isSent ? "Package has already been sent" : "Send Package"}>
                <CheckSquareOutlined style={{ marginRight: 8 }} />
                {isSent ? "Package Sent" : "Send Package"}
              </Tooltip>
            </Button>
          );
        },
        width: 100,
      },
      {
        Header: "Reference Number",
        accessor: "reference_number",
      },
      {
        Header: "Full Name",
        accessor: "fullName",
      },
      {
        Header: "Email Address",
        accessor: "email_address",
      },
      {
        Header: "Contact Number",
        accessor: "contact_number",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Status",
        accessor: "is_sent",
        Cell: ({ row: { original } }) => (
          <span>{sentPackages[original.id] || original.is_sent ? "Package sent" : "Pending"}</span>
        ),
      },
    ],
    [riderPackageData, sentPackages]
  );

  useEffect(() => {
    dispatch(fetchAllRidersPackages(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => (
    <LayoutContent>
      <ServerSideTable
        columns={columns}
        data={riderPackageData}
        loading={loading}
        serverSidePagination={{
          totalRecord,
          onClickNextPage,
          onClickPreviousPage,
          currentPage,
          nextPage,
          previousPage,
          onClickFirstPage,
          onClickLastPage,
        }}
        customFilters={customFilters}
        dateFilters={{ selectedDate }}
      />
    </LayoutContent>
  );

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <Modal
            width={350}
            title="Send package to this rider?"
            open={openModal}
            footer={null}
            closable={false}
          >
            <Formik
              initialValues={{ trackingNumber: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSendPackage}
            >
              {({ handleSubmit, isValid, dirty }) => (
                <FormikForm onSubmit={handleSubmit}>
                  <Field name="trackingNumber">
                    {({ field }) => (
                      <Form.Item label="Tracking Number">
                        <Input {...field} placeholder="Enter tracking number" />
                        <ErrorMessage name="trackingNumber" component="div" />
                      </Form.Item>
                    )}
                  </Field>

                  <Form.Item style={{ textAlign: "center" }}>
                    <Button type="primary" htmlType="submit" loading={confirmLoading} disabled={!dirty || !isValid}>
                      Send Package
                    </Button>
                    <Button
                    style={{ marginLeft: "8px" }}
                    onClick={() => setOpenModal(false)}
                  >
                    Cancel
                  </Button>
                  </Form.Item>
                </FormikForm>
              )}
            </Formik>
          </Modal>
        }
      />
    </>
  );
};

export default RiderPackageMonitoring;
